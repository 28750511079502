import React, { useState, useEffect } from "react"
import Helmet from "react-helmet"

const Layout = data => {
  const { id } = data.params
  const [user, setUser] = useState("Foo")
  useEffect(() => {
    fetch(`/.netlify/functions/user?uid=${id}`)
      .then(response => response.json())
      .then(data => {
        setUser(data.name)
      })
      .catch(err => {
        setUser(err.message)
      })
  }, [id])

  return (
    <div>
      <Helmet title="Roots — User" />
      User: {user}
      <pre style={{ maxWidth: "100%", wordBreak: "break-word" }}>
        {JSON.stringify(data)}
      </pre>
    </div>
  )
}

export default Layout
